<template>
    <div>
        <ValidationObserver ref="observer_cust" tag="form" v-slot="{ handleSubmit }" @submit.prevent>
            <b-row>
                <b-col cols="12">
                    <div>
                        <b-row no-gutters align-v="center">
                            <b-col sm="12">
                                <label class="mb-1">{{ $t('common_name') }}</label><small class="text-danger"><em>
                                        *</em></small>
                                <ValidationProvider name="Name" rules="required" v-slot="v">
                                    <b-form-input spellcheck="false" type="text" autocomplete="off"
                                        v-model="customer.name"></b-form-input>
                                    <small class="text-danger"><em>{{ v.errors[0] }}</em></small>
                                </ValidationProvider>
                            </b-col>
                        </b-row>
                        <b-row no-gutters align-v="center" class="mt-2">
                            <b-col sm="12">
                                <label class="mb-1">{{ $t('common_email') }}</label><small class="text-danger"><em>
                                        *</em></small>
                                <ValidationProvider name="Email" rules="required|email" v-slot="v">
                                    <b-form-input spellcheck="false" type="text" autocomplete="off"
                                        v-model="customer.email"></b-form-input>
                                    <small class="text-danger"><em>{{ v.errors[0] }}</em></small>
                                </ValidationProvider>
                            </b-col>
                        </b-row>
                        <b-row no-gutters align-v="center" class="mt-2">
                            <b-col sm="12">
                                <label class="mb-1">{{ $t('common_number') }}</label>
                                    <b-form-input spellcheck="false" type="text" autocomplete="off"
                                        v-model="customer.phoneNumber"></b-form-input>
                            </b-col>
                        </b-row>
                        <b-row no-gutters align-v="center" class="mt-2">
                            <b-col sm="12">
                                <label class="mb-1">{{ $t('common_organisation_number') }}</label><small
                                    class="text-danger"><em> *</em></small>
                                <ValidationProvider name="Organisation number" rules="required" v-slot="v">
                                    <b-form-input spellcheck="false" type="text" autocomplete="off"
                                        v-model="customer.organisationNumber"></b-form-input>
                                    <small class="text-danger"><em>{{ v.errors[0] }}</em></small>
                                </ValidationProvider>
                            </b-col>
                        </b-row>
                        <b-row no-gutters align-v="center" class="mt-2">
                            <b-col sm="12">
                                <label class="mb-1">{{ $t('common_gate_installer') }}</label>
                                <b-form-checkbox v-model="customer.isGateInstaller"></b-form-checkbox>
                            </b-col>
                        </b-row>
                    </div>
                    <b-row class="mt-2">
                        <b-col cols="12">
                            <div class="float-left">
                                <b-button variant="warning" @click="switchToGate">
                                    {{ $t('common_cancel') }}
                                </b-button>
                            </div>
                            <div class="float-right">
                                <b-button variant="success" type="submit" @click="handleSubmit(saveCustomer)">
                                    {{ $t('common_save') }}
                                </b-button>
                            </div>
                        </b-col>
                    </b-row>
                </b-col>
            </b-row>
        </ValidationObserver>
    </div>
</template>

<style scoped></style>

<script>
import customerService from '@/services/customerService';

export default {
    name: 'customerAdd',
    data: () => ({
        customer: {
            id: 0,
            name: '',
            email: '',
            phoneNumber: '',
            organisationNumber: '',
            isGateInstaller: false,
        },
    }),
    methods: {
        saveCustomer() {
            this.$refs.observer_cust.validate().then(success => {
                if (!success) {
                    return;
                } else {
                    customerService.createCustomer(this.customer)
                        .then(data => {
                            this.$emit('reloadcustomerlist', data.customerId);
                            this.switchToGate();
                        });
                }
            });
        },
        switchToGate() {
            this.$emit('closeaddcustomer');
        }
    },
    mounted() {
    },
}
</script>