<template>
    <div>
        <dmx-title :bc-items="bcItems">{{ $t('admin_dashboard_gates_title') }}</dmx-title>
        <b-row align-v="center" class="mb-1">
            <b-col>
                <div v-if="gateList.length || options.perPage == -2">
                    {{ pagination.totalListCount }} gates found
                    <span class="ml-3">
                        Show: <b-link @click="setPerPage(10)"
                                      :style="((options.perPage == 10) ? 'font-weight: bold;' : ' ')">10</b-link> |
                        <b-link @click="setPerPage(20)"
                                :style="((options.perPage == 20) ? 'font-weight: bold;' : ' ')">20</b-link> |
                        <b-link @click="setPerPage(100)"
                                :style="((options.perPage == 100) ? 'font-weight: bold;' : ' ')">100</b-link> |
                        <b-link @click="setPerPage(-1)"
                                :style="((options.perPage == -1) ? 'font-weight: bold;' : ' ')">All</b-link> |
                                <b-link @click="setPerPage(-2)"
                                :style="((options.perPage == -2) ? 'font-weight: bold;' : ' ')">Connected</b-link>
                    </span>
                </div>
                <div v-else>
                    <b-icon icon="three-dots" animation="cylon" class="ml-3"></b-icon>
                </div>
            </b-col>
            <b-col>
                <b-input v-model="searchField" @input="searchWithText()" :loading="isLoading"
                         :placeholder="$t('common_search')">
                </b-input>
            </b-col>
            <b-col align="right">
                <b-button v-if="stateUser.userRole >= userRoles.DemexAdmin" variant="success" v-b-modal.add-gate-modal>
                    <b-icon icon="plus"></b-icon>
                    {{ $t('common_create') }}
                </b-button>
            </b-col>
        </b-row>
        <b-tabs lazy pills small content-class="mt-2">
            <b-tab v-on:click="setActiveTab(0)" :active="activeTab === 0" title="List view">

                <b-table id="gates-table" thead-class="dmx-thead-block" tbody-tr-class="dmx-pointer-element" :busy="loading"
                         outlined striped bordered hover :items="gateList" :fields="headers" @row-clicked="editGate">
                    <template #cell(id)="{ item }">
                        <span v-bind:class="{ 'setRed': item.stageId > 0 }">
                            {{ item.id }}
                        </span>
                    </template>
                    <!--Status might need a new component For DeviceConnected , PowerConnected, GateClosed/Open-->
                    <template #cell(status)="{ value, item }">
                    <table-status :item="item" />
                    </template>
<!--                     <template #cell(status)="{ item }">
                        {{ statesFormatted(item.status) }}
                    </template> -->
                    <template #cell(createdOnUtc)="{ value }">
                        {{ utcFormatted(value) }}
                    </template>
                </b-table>

            </b-tab>
            <b-tab v-on:click="setActiveTab(1)" :active="activeTab === 1" title="Map view">
                <div style="height: 600px;" class="mb-3">
                    <gate-map :gates="gateList" @marker-clicked="markerClicked"></gate-map>
                </div>
            </b-tab>
        </b-tabs>
        <div>
            <b-pagination v-if="gateList.length > 0 && showPagination" align="center" v-model="options.currentPage" :total-rows="pagination.totalListCount"
                          :per-page="options.perPage" aria-controls="gates-table">
            </b-pagination>
        </div>
        <!-- Modal for gate-addnew -->
        <b-modal :no-close-on-backdrop="true" ref="addgate" hide-footer hide-header size="md" id="add-gate-modal"
                 title="Add gate" body-class="p-0">
            <gate-addnew @closeaddgate="closeAddNewModal" :gateId="addEditGateId"></gate-addnew>
        </b-modal>
    </div>
</template>

<style scoped>
    .setRed {
        color: red;
    }
</style>

<script>
    import { UserRoles } from '@/variables/variables.js'
    import { mapState } from 'vuex';
    import gateService from '@/services/gateService';
    import router from '@/router';
    import gateMapComponent from '@/components/gates/gate-map.vue';
    import gateAddnewComponent from '@/components/gates/gate-addnew.vue';
    import gatetableStatusComponent from '@/components/gates/table-status.vue';

    export default {
        name: "GateList",
        components: {
            'gate-map': gateMapComponent,
            'gate-addnew': gateAddnewComponent,
            'table-status':gatetableStatusComponent
        },
        data: () => ({
            addEditGateId: 0,
            search: '',
            loading: true,
            options: {
                perPage: 10,
                currentPage: 1
            },

            //flags
            isLoading: false,

            // values
            searchField: '',
            gateList: [],
            userRoles: UserRoles,

            // pagination
            pagination: {
                totalListCount: 0,
            },
            showPagination: true,
            filterTableHeadName: '',
            pageDebouncer: 0,
            timeoutId: 0,
            itemRowCountTracker: 0,
            bcItems: [
                {
                    text: 'gates',
                    href: '/gates',
                    active: true,
                },
            ],
            activeTab: 0,
        }),
        computed: {
            ...mapState({
                stateUser: store => store.authentication.user,
            }),
            headers() {
                return [
                    {
                        label: this.$t('common_id'),
                        align: 'start',
                        sortable: true,
                        key: 'id',
                    },
                    {
                        label: 'Ordernumber',
                        key: 'orderNumber',
                    },
                    {
                        label: 'Device',
                        key: 'deviceName',
                    },
                    {
                        label: 'Status',
                        key: 'status',
                    },
                    {
                        label: 'System name',
                        align: 'start',
                        sortable: true,
                        key: 'systemName',
                    },
                    {
                        label: 'Display name',
                        align: 'start',
                        sortable: true,
                        key: 'displayName',
                    },
                    {
                        label: 'Company',
                        sortable: true,
                        key: 'company',
                    },
                    //{
                    //    label: 'Installer',
                    //    sortable: true,
                    //    key: 'installer',
                    //},
                    //{
                    //    label: 'Active alerts',
                    //    sortable: true,
                    //    key: 'activeAlerts',
                    //},
/*                     {
                        label: this.$t('common_status'),
                        sortable: true,
                        key: 'status',
                    }, */
                    //{
                    //    text: this.$t('admin_dashboard_device'),
                    //    sortable: true,
                    //    value: 'device',
                    //},
                    {
                        label: this.$t('common_created'),
                        sortable: true,
                        key: 'createdOnUtc',
                    }
                ]
            },
            listMapItemsDisabled() {
                let ret = true;
                for (let i in this.gateList) {
                    if (this.gateList[i].deviceLat && this.gateList[i].deviceLon) {
                        ret = false;
                        break;
                    }
                }
                return ret;
            }
        },
        methods: {
            /* statesFormatted(state) {
                let states = {
                    0: "Unknown",
                    10: "Closed",
                    20: "Open",
                    30: "Opening",
                    40: "Closing",
                }
                return states[state];
            }, */
            setPerPage(num) {
                this.isLoading = true;
                this.options.currentPage = 1;
                if (num < 10) {
                    this.showPagination = false;
                }
                this.options.perPage = num;
            },
            deleteAllGates() {
                gateService.deleteAll();
                this.getGates();
            },
            editGate(e) {
                if (parseInt(e.stageId) === 0) {
                    // StageId is 0 continue to standard edit page
                    router.push({ name: 'editGate', params: { gateId: parseInt(e.id) } })
                } else {
                    // Open create modal and direct to the correct Stage in the creation process
                    this.$refs['addgate'].show();
                    this.addEditGateId = parseInt(e.id);
                }
            },
            markerClicked(id) {
                router.push({ name: 'editGate', params: { gateId: parseInt(id) } })
            },
            searchWithText() {
                this.isLoading = true;

                if (this.timeoutId > 0) {
                    clearTimeout(this.timeoutId);
                }
                this.timeoutId = window.setTimeout(() => {
                    this.getGates();
                }, 600);
            },
            filterByTableHeader(tableHeaderName) {
                this.filterTableHeadName = tableHeaderName;
                this.getGates();
            },
            getGates() {
                this.gateList = [];
                gateService.getGateList("FilterGateList", {
                    Count: this.options.perPage,
                    Gates: [],
                    //SortBy: this.options.sortBy[0],
                    SearchTerm: this.searchField,
                    //SortDesc: this.options.sortDesc[0],
                    Page: this.options.currentPage
                })
                    .then((data) => {
                        this.gateList = data.items;
                        this.pagination.totalListCount = data.totalListCount;
                        this.options.currentPage = data.page;
                        this.options.perPage = data.count;
                        localStorage.setItem('demex_gate_list_page_number', this.options.currentPage);
                        localStorage.setItem('demex_gate_list_per_page', this.options.perPage);
                        if (this.options.perPage >= 10) {
                            this.showPagination = true;
                        } else {
                            this.showPagination = false;
                        }
                        this.loading = false;
                    })
                    .catch(function (error) {
                        alert(error);
                    });
            },
            gateStatusChanged(data) {
                //console.log('SOCKET DATA Got state, gateId', data.state, data.gateId);
                for (let i in this.gateList) {
                    if (this.gateList[i].id == data.gateId) {
                        this.gateList[i].state = data;
                        break;
                    }
                }
            },
            closeAddNewModal() {
                this.addEditGateId = 0;
                this.$refs['addgate'].hide();
                window.setTimeout(() => {
                    this.getGates();
                }, 1000);
            },
            changePage(pageNumber, perPage) {
                this.options.currentPage = pageNumber;
                this.options.perPage = perPage;
            },
            setActiveTab(tab) {
                this.activeTab = tab;
                localStorage.setItem('demex_gate_list_active_tab', tab);
            },
        },
        watch: {
            options: {
                // pagedebouncer wont work like in logs list
                handler() {
                    const self = this;
                    clearTimeout(this.pageDebouncer);
                    this.pageDebouncer = setTimeout(() => {
                        self.getGates()
                    }, 400);
                },
                deep: true,
                immediate: true,
            }
        },
        mounted() {
            let self = this;
            this.$socket.on('GateStatusChange', (data) => {
                self.gateStatusChanged(data);
            });
            const pageNumber = parseInt(localStorage.getItem('demex_gate_list_page_number'));
            const perPage = parseInt(localStorage.getItem('demex_gate_list_per_page'));
            if (pageNumber && perPage) {
                this.changePage(pageNumber, perPage);
            }
            const activeTab = parseInt(localStorage.getItem('demex_gate_list_active_tab'));
            if (activeTab) {
                console.log('lh active tab ' + activeTab)
                this.activeTab = activeTab;
            }
        },
        beforeDestroy() {
            this.$socket.off('GateStatusChange', (err) => { });
        }

    }
</script>
