<template>
    <div class="d-flex">
        <div class="mr-2">
            <div v-if="item.deviceConnected">
                <label :id="'devices_' + item.id" class="badge badge-success">Device</label>
                <b-tooltip :target="'devices_' + item.id"
                    v-if="item.deviceConnectedUpdatedAt && item.deviceConnectedUpdatedAt !== '0001-01-01T00:00:00'"
                    noninteractive>{{ utcFormatted(item.deviceConnectedUpdatedAt) }}</b-tooltip>
            </div>
            <div v-else>
                <label :id="'deviced_' + item.id" class="badge badge-danger">Device</label>
                <b-tooltip :target="'deviced_' + item.id"
                    v-if="item.deviceConnectedUpdatedAt && item.deviceConnectedUpdatedAt !== '0001-01-01T00:00:00'"
                    noninteractive>{{ utcFormatted(item.deviceConnectedUpdatedAt) }}</b-tooltip>
            </div>
        </div>
        <div class="mr-2">
            <div v-if="item.powerConnected">
                <label :id="'powers_' + item.id" class="badge badge-success">Power</label>
                <b-tooltip :target="'powers_' + item.id"
                    v-if="item.powerUpdatedAt && item.powerUpdatedAt !== '0001-01-01T00:00:00'" noninteractive>{{
                        utcFormatted(item.powerUpdatedAt) }}</b-tooltip>
            </div>
            <div v-else>
                <label :id="'powerd_' + item.id" class="badge badge-danger">Power</label>
                <b-tooltip :target="'powerd_' + item.id"
                    v-if="item.powerUpdatedAt && item.powerUpdatedAt !== '0001-01-01T00:00:00'" noninteractive>{{
                        utcFormatted(item.powerUpdatedAt) }}</b-tooltip>
            </div>
        </div>
        <div>
            <div>
                <label :class="'badge ' + statesColor(item.status)">
                    {{ statesFormatted(item.status) }}</label>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    props: {
        item: {},
    },
    methods: {
        statesFormatted(state) {
                let states = {
                    0: "Unknown",
                    10: "Closed",
                    20: "Open",
                    30: "Opening",
                    40: "Closing",
                }
                return states[state];
            },
            statesColor(state) {
                let states = {
                    0: "badge-secondary",
                    10: "badge-danger",
                    20: "badge-success",
                    30: "badge-warning",
                    40: "badge-warning",
                }
                return states[state];
            },
    }
}
</script>